import React from "react";
import HomeImage from "../home.svg";

const Home = () => {
  return (
    <div
      style={{
        width: "503px",
        height: "256px",
        position: "absolute",
        top: "98px",
        right: "0px",
        pointerEvents: "none",
      }}
    >
      <img src={HomeImage} />
    </div>
  );
};

export default Home;
