import React from "react";
import ToolbarImage from "../toolbar.svg";

const Toolbar = () => {
  const sendSleepLights = () => {
    const options = {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      url: "http://localhost:8888",
      signal: AbortSignal.timeout(500),
    };

    fetch("http://localhost:8888/30,333333", options)
      .then((response) => response.json())
      .catch((error) => console.error("Error during setting lights!", error));
  };

  const resetApp = () => {
    // const options = {
    //   method: "POST",
    //   mode: "no-cors",
    //   cache: "no-cache",
    //   url: "http://localhost:8888",
    //   signal: AbortSignal.timeout(500),
    // };

    // fetch("http://localhost:8888/effect-on", options)
    //   .then((response) => response.json())
    //   .catch((error) => console.error("Error during setting lights!", error));

    // setTimeout(function () {
    //   sendSleepLights();
    //   window.location.reload();
    // }, 1000);
  };

  return (
    <div
      style={{ width: "405px", height: "90px"}}>
      <img draggable="false" src={ToolbarImage} />
      {/* <div
        onClick={resetApp}
        style={{ position: "absolute", width: "90px", height: "90px", top:"0px", right:"0px"}}
      ></div> */}
    </div>
  );
};

export default Toolbar;
