import React, { useRef, useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import "./App.css";
import Navigation from "./components/Navigation.js";
import CenterWidget from "./components/CenterWidget.js";
import Toolbar from "./components/Toolbar.js";
import Content from "./components/Content.js";
import { wait } from "@testing-library/user-event/dist/utils";

const timeout = 200_000; // 200s possibly subject to change.

function App() {
  const [navPage, setNavPage] = useState("home");
  const [visibility, setVisibility] = useState("visible");
  const [state, setState] = useState("Idle");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    // Disable text selection for elements
    // with class "no-select"
    const noSelectElements = document.querySelectorAll(".no-select");
    noSelectElements.forEach((element) => {
      element.style.webkitUserSelect = "none";
      element.style.mozUserSelect = "none";
      element.style.msUserSelect = "none";
      element.style.userSelect = "none";
    });
  }, []);

  const onIdle = () => {
    setState("Idle");
    console.log("Is now idle");
    sleep();
  };

  const onActive = () => {
    setState("Active");
    console.log("Is now active");
    wakeUp();
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: timeout,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (navPage !== "light" && visibility === "hidden") {
      sendWakeLights();
    }
  }, [navPage]);

  const wakeUp = () => {
    setVisibility("hidden");
    sendWakeLights();
    console.log("wake up", visibility);
  };

  const sleep = () => {
    setVisibility("visible");
    setNavPage("home");
    sendEffect();
  };

  const sendWakeLights = () => {
    const options = {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      url: "http://localhost:8888",
      signal: AbortSignal.timeout(500),
    };

    fetch("http://localhost:8888/50,777777", options)
      .then((response) => response.json())
      .catch((error) => console.error("Error during setting lights!", error));
  };

  const sendSleepLights = () => {
    const options = {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      url: "http://localhost:8888",
      signal: AbortSignal.timeout(500),
    };

    fetch("http://localhost:8888/30,333333", options)
      .then((response) => response.json())
      .catch((error) => console.error("Error during setting lights!", error));
  };

  const sendEffect = () => {
    const options = {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      url: "http://localhost:8888",
      signal: AbortSignal.timeout(500),
    };

    fetch("http://localhost:8888/effect-on", options)
      .then((response) => response.json())
      .catch((error) => console.error("Error during setting lights!", error));

    setTimeout(function () {
      sendSleepLights();
    }, 700);
  };

  return (
    <div
      className="App"
      style={{ position: "relative", width: "1919px", height: "719px" }}
    >
      <div
        className="no-select"
        style={{
          position: "absolute",
          right: "84px",
          top: "93px",
          width: "700px",
          height: "700px",
        }}
      >
        <Content page={navPage} />
      </div>
      <div
        className="no-select"
        style={{
          position: "absolute",
          width: "908px",
          height: "718px",
          left: "361px",
          top: "4px",
        }}
      >
        <CenterWidget title={navPage} />
      </div>
      <div
        className="no-select"
        style={{
          position: "absolute",
          width: "900px",
          height: "719px",
          left: "100px",
        }}
      >
        <Navigation navPage={navPage} onPageChange={setNavPage} />
      </div>
      <div
        className="no-select"
        style={{
          position: "absolute",
          right: "25px",
          bottom: "38px",
          width: "405px",
          height: "90px",
        }}
      >
        <Toolbar />
        <div
          onClick={onIdle}
          style={{ position: "absolute", width: "90px", height: "90px", top:"0px", right:"0px"}}
        ></div>
      </div>
      <div style={{ position: "relative", display: "inline-block" }}>
        <div
          onClick={wakeUp}
          style={{
            visibility: visibility,
            opacity: visibility == "hidden" ? 0 : 1,
            transition: "all 2s",
            position: "fixed",
            width: "1919px",
            height: "719px",
            left: "0px",
            top: "0px",
            backgroundColor: "black",
          }}
        ></div>
      </div>
      <div
        onClick={onIdle}
        style={{ position: "absolute", width: "150px", height: "719px", left:"0px"}}
      ></div>
    </div>
  );
}

export default App;
