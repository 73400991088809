import React, { useState, useRef, useEffect } from "react";
import VideoPlayer from "./VideoPlayer.js";
import ProgressImage from "../entertainment/progress.svg";
import InfoImage from "../entertainment/info.svg";
import PlayImage from "../entertainment/play.svg";
import PreviewImage from "../entertainment/preview-image.png";

const Entertainment = () => {
  const [playerVisible, setPlayerVisible] = useState(false);

  const openPlayer = () => {
    console.log("open player");
    setPlayerVisible(true);
  };

  const closePlayer = () => {
    setPlayerVisible(false);
  };

  return (
    <div style={{ width: "709px", height: "500px", position: "relative" }}>
      <div style={{ position: "relative", display: "inline-block" }}>
        <VideoPlayer  visible={playerVisible} onClose={closePlayer} />
      </div>
      <div
        style={{
          width: "709px",
          height: "397px",
          position: "absolute",
          right: "0px",
          top: "0px",
        }}
      >
        <img draggable="false" src={PreviewImage} />
      </div>
      <div
        style={{
          width: "293px",
          height: "27px",
          position: "absolute",
          right: "283px",
          top: "427px",
        }}
      >
        <img draggable="false" src={ProgressImage} />
      </div>
      <div
        style={{
          width: "129px",
          height: "55px",
          position: "absolute",
          right: "0px",
          top: "418px",
        }}
      >
        <img draggable="false" src={InfoImage} />
      </div>
      <div
        onClick={openPlayer}
        style={{
          width: "96px",
          height: "96px",
          position: "absolute",
          right: "266px",
          top: "153px",
        }}
      >
        <img draggable="false" src={PlayImage} />
      </div>
    </div>
  );
};

export default Entertainment;
