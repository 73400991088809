import React from "react";
import CenterWidgetImage from "../center-widget/ring.png";
import HomeTitle from "../navigation/home.svg";
import EntertainmentTitle from "../navigation/entertainment.svg";
import LightsTitle from "../navigation/light.svg";
import TemperatureTitle from "../navigation/temperature.svg";
import ServiceTitle from "../navigation/service.svg";
import PresetsTitle from "../navigation/presets.svg";

const CenterWidget = ({ title }) => {
  const Title = ({ title }) => {
    switch (title) {
      default:
      case "home":
        return <img src={HomeTitle} style={{ pointerEvents: "none" }} />;
      case "entertainment":
        return (
          <img src={EntertainmentTitle} style={{ pointerEvents: "none" }} />
        );
      case "light":
        return <img src={LightsTitle} style={{ pointerEvents: "none" }} />;
      case "temperature":
        return <img src={TemperatureTitle} style={{ pointerEvents: "none" }} />;
      case "service":
        return <img src={ServiceTitle} style={{ pointerEvents: "none" }} />;
      case "presets":
        return <img src={PresetsTitle} style={{ pointerEvents: "none" }} />;
    }
  };

  return (
    <div style={{ width: "908px", height: "718px", position: "relative" }}>
      <img
        src={CenterWidgetImage}
        alt=""
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          pointerEvents: "none",
          width: "908px",
          height: "718px",
        }}
      />
      <div
        style={{
          position: "absolute",
          width: "618px",
          height: "30px",
          right: "0px",
          top: "342px",
        }}
      >
        <Title title={title} />
      </div>
    </div>
  );
};

export default CenterWidget;
