import React, { useState, useRef, useEffect } from "react";
import NavigationShadow from "../navigation-shadow.svg";
import NavigationCrosshairs from "../navigation-crosshairs.svg";
import HomeIcon from "../home-icon.svg";
import EntertainmentIcon from "../entertainment-icon.svg";
import LightIcon from "../light-icon.svg";
import TemperatureIcon from "../temperature-icon.svg";
import ServiceIcon from "../service-icon.svg";
import PresetsIcon from "../presets-icon.svg";

const Navigation = ({ onPageChange, navPage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [targetAngle, setTargetAngle] = useState(0);
  const [actualAngle, setActualAngle] = useState(0);
  const [touchY, setTouchY] = useState(0);
  const [touchActive, setTouchActive] = useState(false);
  const [touchAngle, setTouchAngle] = useState(null);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  useEffect(() => {
    if(navPage === "home" && activeIndex != 0) {
      setActiveIndex(0);
    }
  }, [navPage]);

  useEffect(() => {
    onPageChange(data()[activeIndex].name);
  }, [activeIndex]);

  useEffect(() => {
    setTargetAngle(activeIndex * 35);
  }, [activeIndex]);

  useEffect(() => {
    if (Math.abs(actualAngle - targetAngle) < 1) {
      setActualAngle(targetAngle);
    } else if (actualAngle < targetAngle) {
      setActualAngle(actualAngle + 1);
    } else if (actualAngle > targetAngle) {
      setActualAngle(actualAngle - 1);
    }
  }, [targetAngle, actualAngle]);

  const data = () => {
    return [
      {
        name: "home",
        icon: HomeIcon,
      },
      {
        name: "entertainment",
        icon: EntertainmentIcon,
      },
      {
        name: "light",
        icon: LightIcon,
      },
      {
        name: "temperature",
        icon: TemperatureIcon,
      },
      {
        name: "service",
        icon: ServiceIcon,
      },
      {
        name: "presets",
        icon: PresetsIcon,
      },
    ];
  };

  const degToRad = (deg) => {
    return (deg / 180) * Math.PI;
  };

  const Crescent = ({ activeIndex }) => {
    const offsetX = 484;
    const offsetY = 316;
    const radius = 300;
    const beta = 35; // Angle in deg between two entries.

    const angle = activeIndex * beta;

    const handleImageUp = (e, datum) => {
      console.log("mouse up image", e, datum);
      setPosition(datum);
      setTouchActive(false);
    };

    return data().map((datum, index) => {
      const x =
        offsetX - radius * Math.cos(degToRad(actualAngle - index * beta));
      const y =
        offsetY - radius * Math.sin(degToRad(actualAngle - index * beta));
      const opacity = 1.0 - Math.abs(index - activeIndex) * 0.4;
      return (
        <div
          key={datum.name}
          onMouseUp={(e) => handleImageUp(e, datum)}
          // onClick={() => setPosition(datum)}
          style={{
            width: "90px",
            heigth: "90px",
            position: "absolute",
            left: x,
            top: y,
            opacity: opacity,
          }}
        >
          <img
            src={datum.icon}
            style={{
              pointerEvents: "none",
              WebkitTapHighlightColor: "transparent",
            }}
          />
        </div>
      );
    });
  };

  const spinUp = () => {
    if (activeIndex < data().length - 1) {
      setActiveIndex(activeIndex + 1);
    }
    setTargetAngle(activeIndex * 35);
  };

  const spinDown = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
    setTargetAngle(activeIndex * 35);
  };

  const setPosition = (newPageObject) => {
    setActiveIndex(
      data().findIndex((obj) => {
        return obj.name == newPageObject.name;
      })
    );
  };

  const handleMD = (e) => {
    console.log("md", e);
    setTouchActive(true);
    setTouchY(e.screenY);
    setTouchAngle(actualAngle);
  };

  const handleMU = (e) => {
    console.log("mu", e);
    const muy = e.screenY;
    if (touchActive) {
      if (muy > touchY) {
        spinDown();
      } else if (muy < touchY) {
        spinUp();
      }
    }
    setTouchActive(false);
  };

  const handleMouseMove = (e) => {
    if (touchActive) {
      const delta = (e.screenY - touchY) / 10;
      console.log(delta);
      setTargetAngle(touchAngle - delta);
    }
  };

  return (
    <div
      onMouseDown={handleMD}
      onMouseUp={handleMU}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMU}
      style={{ position: "relative", width: "517px", height: "719px" }}
    >
      <img
        src={NavigationShadow}
        style={{
          position: "absolute",
          left: "139px",
          top: "399px",
          pointerEvents: "none",
          WebkitTapHighlightColor: "transparent",
        }}
      />
      <img
        src={NavigationCrosshairs}
        style={{
          position: "absolute",
          left: "167px",
          top: "193px",
          pointerEvents: "none",
          WebkitTapHighlightColor: "transparent",
        }}
      />
      <Crescent activeIndex={activeIndex} />
    </div>
  );
};

export default Navigation;
