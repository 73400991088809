import React, { useRef, useEffect } from "react";
import { Alpha, Hue, useColor } from "react-color-palette";
import "react-color-palette/css";
import Bulb from "../lights/bulb.svg";
import Halo from "../lights/halo.svg";

const LightControl = () => {
  const [color, setColor] = useColor("hsl(120 100% 50% / .5)");
  const canvasRef = useRef(null);

  useEffect(() => {
    paintCone(color);
  }, [color]);

  const paintCone = (color) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const grad = context.createRadialGradient(250, 0, 0, 250, 0, 401);
    grad.addColorStop(0, color.hex);
    grad.addColorStop(1, "#00000000");
    context.fillStyle = grad;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.beginPath();
    context.moveTo(250, 0);
    context.lineTo(0, 300);
    context.lineTo(500, 300);
    context.lineTo(250, 0);
    context.fill();
  };

  const cleanHex = (hex) => {
    let result = hex.replace("#", "").toUpperCase();
    if (hex.length === 9) {
      result = result.slice(0, 6); // Remove the last two characters (alpha)
    }
    return result;
  };

  const sendValue = (value) => {
    const options = {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      url: "http://localhost:8888",
      signal: AbortSignal.timeout(500),
    };

    const brightness = Math.max(Math.min(value.rgb.a * 100, 99), 1).toString();
    const hue = cleanHex(value.hex);

    console.log("http://localhost:8888/" + brightness + "," + hue);

    fetch("http://localhost:8888/" + brightness + "," + hue, options)
      .then((response) => response.json())
      .catch((error) => console.error("Error during setting lights!", error));
  };

  return (
    <div style={{ width: "718px", height: "515px", position: "relative"}}>
      <img
        src={Halo}
        style={{
          position: "absolute",
          left: "417px",
          top: "0px",
          pointerEvents: "none",
        }}
      />
      <div style={{ width: "500px", height: "300px", position: "absolute", right:"0px", top:"2px"}}>
        <canvas ref={canvasRef} width={500} height={300} />
      </div>
      <div
        style={{
          width: "350px",
          height: "200px",
          position: "absolute",
          left: "300px",
          top: "330px",
        }}
      >
        <Alpha color={color} onChange={setColor} onChangeComplete={sendValue} />
      </div>
      <div
        style={{
          width: "350px",
          height: "200px",
          position: "absolute",
          left: "300px",
          top: "380px",
        }}
      >
        <Hue color={color} onChange={setColor} onChangeComplete={sendValue} />
      </div>
      <img
        src={Bulb}
        style={{
          position: "absolute",
          left: "451px",
          top: "15px",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default LightControl;
