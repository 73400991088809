import React from "react";
import ReactPlayer from "react-player";
import Back from "../entertainment/back.svg";

// TODO autostart possible?

const VideoPlayer = ({ visible, onClose }) => {
  const close = () => {
    onClose();
  };

  const getVisibility = (visible) => {
    if (visible) {
      return "visible";
    } else {
      return "hidden";
    }
  };

  return (
    <div
      style={{
        width: "1919px",
        height: "719px",
        position: "fixed",
        left:"0px",
        top:"0px",
        backgroundColor: "black",
        zIndex:1,
        visibility: getVisibility(visible),
      }}
    >
      <div style={{posision:"relative"}}>
      <ReactPlayer
        url="Surf_video.mp4"
        controls={true}
        width={1919}
        height={719}
        volume={0}
        muted={true}
        playing={true}
      />
      <div
        onClick={close}
        style={{
          width: "78px",
          heigh: "78px",
          position: "absolute",
          top: "31px",
          right: "31px",
        }}
      >
        <img src={Back} style={{ pointerEvents: "none" }} />
      </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
