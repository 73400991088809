import React, { useRef, useEffect } from "react";
import Home from "./Home.js";
import Entertainment from "./Entertainment.js";
import LightControl from "./LightControl.js";
import Service from "./Service.js";
import Presets from "./Presets.js";
import RadialSlider from "./RadialSlider.js"

const Content = ({ page }) => {
  const displayPage = (page) => {
    switch (page) {
      default:
      case "home":
        return <Home />;
      case "entertainment":
        return <Entertainment />;
      case "light":
        return <LightControl />;
      case "temperature":
        return <RadialSlider />;
      case "service":
        return <Service />;
      case "presets":
        return <Presets />;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "718px",
        height: "700px",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {displayPage(page)}
    </div>
  );
};

export default Content;
