import React, { useState, useRef, useEffect } from "react";
import TemperatureWheel from "../temperature-wheel.svg";
import Deg17 from "../temperature/deg17.svg";
import Deg18 from "../temperature/deg18.svg";
import Deg19 from "../temperature/deg19.svg";
import Deg20 from "../temperature/deg20.svg";
import Deg21 from "../temperature/deg21.svg";
import Deg22 from "../temperature/deg22.svg";
import Deg23 from "../temperature/deg23.svg";
import Deg24 from "../temperature/deg24.svg";
import Deg25 from "../temperature/deg25.svg";
import Deg26 from "../temperature/deg26.svg";
import Fahrenheit62 from "../temperature/fahrenheit62.svg";
import Fahrenheit64 from "../temperature/fahrenheit64.svg";
import Fahrenheit66 from "../temperature/fahrenheit66.svg";
import Fahrenheit68 from "../temperature/fahrenheit68.svg";
import Fahrenheit70 from "../temperature/fahrenheit70.svg";
import Fahrenheit72 from "../temperature/fahrenheit72.svg";
import Fahrenheit73 from "../temperature/fahrenheit73.svg";
import Fahrenheit75 from "../temperature/fahrenheit75.svg";
import Fahrenheit77 from "../temperature/fahrenheit77.svg";
import Fahrenheit79 from "../temperature/fahrenheit79.svg";

const cx = 284;
const cy = 258;
const angleOffset = 135;

const RadialSlider = () => {
  const [targetAngle, setTargetAngle] = useState(0);
  const [actualAngle, setActualAngle] = useState(0);
  const [temperatureValue, setTemperatureValue] = useState(5);
  const [touchActive, setTouchActive] = useState(0);
  const [useDeg, setUseDeg] = useState(true);

  const divRef = useRef(null);

  useEffect(() => {
    if (Math.abs(actualAngle - targetAngle) < 1) {
      setActualAngle(targetAngle);
    } else if (actualAngle < targetAngle) {
      setActualAngle(actualAngle + 1);
    } else if (actualAngle > targetAngle) {
      setActualAngle(actualAngle - 1);
    }
  }, [targetAngle, actualAngle]);

  useEffect(() => {
    //compute the value.
    setTemperatureValue(parseInt((actualAngle + 13) / 27));
  }, [actualAngle]);

  const toggleUnits = () => {
    setUseDeg(!useDeg);
  };

  const degToRad = (deg) => {
    return (deg / 180) * Math.PI;
  };

  const radToDeg = (rad) => {
    return (rad * 180) / Math.PI;
  };

  const CustomLabel = (temperatureValue) => {
    const degImage = (deg) => {
      switch (deg) {
        default:
        case 0:
          return <img src={Deg17} style={{ pointerEvents: "none" }} />;
        case 1:
          return <img src={Deg18} style={{ pointerEvents: "none" }} />;
        case 2:
          return <img src={Deg19} style={{ pointerEvents: "none" }} />;
        case 3:
          return <img src={Deg20} style={{ pointerEvents: "none" }} />;
        case 4:
          return <img src={Deg21} style={{ pointerEvents: "none" }} />;
        case 5:
          return <img src={Deg22} style={{ pointerEvents: "none" }} />;
        case 6:
          return <img src={Deg23} style={{ pointerEvents: "none" }} />;
        case 7:
          return <img src={Deg24} style={{ pointerEvents: "none" }} />;
        case 8:
          return <img src={Deg25} style={{ pointerEvents: "none" }} />;
        case 9:
        case 10:
          return <img src={Deg26} style={{ pointerEvents: "none" }} />;
      }
    };

    const fahrenheitImage = (deg) => {
      switch (deg) {
        default:
        case 0:
          return <img src={Fahrenheit62} style={{ pointerEvents: "none" }} />;
        case 1:
          return <img src={Fahrenheit64} style={{ pointerEvents: "none" }} />;
        case 2:
          return <img src={Fahrenheit66} style={{ pointerEvents: "none" }} />;
        case 3:
          return <img src={Fahrenheit68} style={{ pointerEvents: "none" }} />;
        case 4:
          return <img src={Fahrenheit70} style={{ pointerEvents: "none" }} />;
        case 5:
          return <img src={Fahrenheit72} style={{ pointerEvents: "none" }} />;
        case 6:
          return <img src={Fahrenheit73} style={{ pointerEvents: "none" }} />;
        case 7:
          return <img src={Fahrenheit75} style={{ pointerEvents: "none" }} />;
        case 8:
          return <img src={Fahrenheit77} style={{ pointerEvents: "none" }} />;
        case 9:
        case 10:
          return <img src={Fahrenheit79} style={{ pointerEvents: "none" }} />;
      }
    };

    if (!useDeg) {
      return (
        <div onClick={toggleUnits}>{fahrenheitImage(temperatureValue)}</div>
      );
    } else {
      return <div onClick={toggleUnits}>{degImage(temperatureValue)}</div>;
    }
  };

  const Knob = () => {
    const radius = 175;

    const x = cx + Math.cos(degToRad(actualAngle + angleOffset)) * radius - 20;
    const y = cy + Math.sin(degToRad(actualAngle + angleOffset)) * radius - 20;
    return (
      <div
        style={{
          position: "absolute",
          top: y,
          left: x,
          backgroundColor: "white",
          width: "40px",
          height: "40px",
          borderRadius: "50px",
        }}
      ></div>
    );
  };

  const handleMouseDown = (e) => {
    setTouchActive(true);
  };

  const handleMouseUp = (e) => {
    const muy = e.screenY;
    if (touchActive) {
      // finished drag
    }
    setTouchActive(false);
  };

  const handleMouseMove = (e) => {
    if (touchActive) {
      // TODO: find way to compute magic numbers, offsetLeft of divRef.current. not working.
      const x = e.pageX - 1268 - cx;
      const y = e.pageY - 93 - cy;
      const r2 = (x * x) + (y * y);
      if (r2 > 12000) {
        const angle = Math.atan2(y, x);
        const degrees = (radToDeg(angle) + 720 - angleOffset) % 360;
        const newTargetAngle2 = ((radToDeg(angle) + 720) % 360) - angleOffset;
        let newTargetAngle = degrees;
        if (newTargetAngle2 < 0 && newTargetAngle > 300) {
          newTargetAngle = 0;
        } else if (newTargetAngle > 270) {
          newTargetAngle = 270;
        }
        setTargetAngle(newTargetAngle);
      }
    }
  };

  return (
    <div
      ref={divRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      style={{
        width: "567px",
        height: "515px",
        position: "relative",
      }}
    >
      <img
        src={TemperatureWheel}
        style={{
          pointerEvents: "none",
          position: "absolute",
          left: "0px",
          top: "-23px",
        }}
      />
      {Knob()}
      <div
        style={{
          position: "absolute",
          width: "120px",
          height: "118px",
          top: "200px",
          left: cx - 60,
        }}
      >
        {CustomLabel(temperatureValue)}
      </div>
    </div>
  );
};

export default RadialSlider;
