import React from 'react';
import ServiceImage from '../voice-animation/conversation.svg';

const Service = () => {
    return(
      <div style = {{ width:  "546px", height: "346x" }}>
        <div style = {{ width:  "520px", height: "346x" }}>
            <img draggable="false" src={ServiceImage} />
        </div>
        <div></div>
      </div>
    );
  };
  
  export default Service;
