import React from "react";
import PresetsImage from "../presets.svg";

const Presets = () => {
  return (
    <div
      style={{
        width: "546px",
        height: "382x",
        position: "absolute",
        top: "78px",
        right: "-60px",
        pointerEvents: "none",
      }}
    >
      <img src={PresetsImage} />
    </div>
  );
};

export default Presets;
